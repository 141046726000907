<template>
  <layout>
    <page-title :icon="`lni lni-${id > 0 ? 'pencil' : 'plus'}`" :breadcrumb="breadcrumb">
      {{ id > 0 ? 'Editar Professor' : 'Novo Professor' }}
    </page-title>
    <div class="card">
      <div class="card-content">
        <div class="form">
          <div v-if="error" class="notification is-danger field">
            <button class="delete" @click="error = null"></button>
            {{ error }}
          </div>
          <div class="field">
            <label class="label" for="nome">Nome<asterisk /></label>
            <div class="control">
              <input v-model="name" class="input" type="text" name="nome" id="nome" placeholder="Nome do professor" ref="name" required />
            </div>
          </div>
          <div class="field">
            <label class="label" for="email">Email<asterisk /></label>
            <div class="control">
              <input v-model="email" class="input" type="text" name="email" id="email" placeholder="Email do professor" ref="email" required />
            </div>
          </div>
          <div class="field">
            <label class="label" for="password">Senha<asterisk /></label>
            <div class="control">
              <input v-model="password" class="input" type="password" name="password" id="password" placeholder="Senha de acesso" ref="password" required />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label" for="birthDate">Data de Nascimento<asterisk /></label>
                <div class="control">
                  <input v-model="birthDate" class="input" type="date" name="birthDate" id="birthDate" placeholder="Data de Nascimento" ref="birthDate" required />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label" for="celular">Celular<asterisk /></label>
                <div class="control">
                  <the-mask v-model="phone" class="input" type="text" name="celular" id="celular" placeholder="Número do professor" ref="phone" mask="(##) # #### ####" required />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label" for="bio">Bio</label>
            <textarea v-model="bio" class="textarea" name="bio" id="bio" placeholder="Biografia do professor"></textarea>
          </div>
          <div class="field">
            <label class="label" for="resume">Currículo</label>
            <textarea v-model="resume" class="textarea" name="resume" id="resume" placeholder="Currículo do professor"></textarea>
          </div>
          <div class="field is-grouped is-grouped-centered mt-5">
            <div class="control">
              <button class="button is-link" :class="{'is-loading': enviando}" @click="send()">Salvar Professor</button>
            </div>
            <div class="control">
              <router-link class="button is-link is-light" to="/admin/professores">Cancelar</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import api from '@/api'
import validator from 'validator'
import { format } from 'date-fns'
import { DASHBOARD, TEACHER_LIST, TEACHER_FORM } from '@/config/breadcrumb/admin'
import Swal from 'sweetalert2'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import Asterisk from '@/components/ui/Asterisk'

export default {
  name: 'TeacherForm',
  components: {
    Layout,
    PageTitle,
    Asterisk,
    TheMask
  },
  created () {
    if (this.$route.params.id !== undefined) {
      Swal.fire({
        title: 'Carregando...',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })

      api.get(`/teachers/${this.$route.params.id}`).then(res => {
        if (res.status === 200) {
          this.id = res.data.id
          this.name = res.data.name
          this.birthDate = format(new Date(res.data.birthDate), 'yyyy-MM-dd')
          this.email = res.data.email
          this.phone = res.data.phone
          this.bio = res.data.bio
          this.resume = res.data.resume
        } else {
          this.error = 'Não foi possivel carregar o professor'
        }

        Swal.close()
      })
    }
  },
  data () {
    return {
      id: 0,
      name: '',
      birthDate: '',
      email: '',
      phone: '',
      bio: '',
      resume: '',
      password: '',
      error: null,
      enviando: false
    }
  },
  computed: {
    breadcrumb () {
      const form = { ...TEACHER_FORM, isActive: true }
      if (this.id > 0) {
        form.text = 'Editar Professor'
      }
      return [DASHBOARD, TEACHER_LIST, form]
    }
  },
  methods: {
    send () {
      this.enviando = true
      if (this.name === '') {
        this.error = 'Nome é obrigátorio'
        this.$refs.name.focus()
        this.enviando = false
        return
      }
      this.error = null

      if (!validator.isEmail(this.email)) {
        this.error = 'Email inválido'
        this.$refs.email.focus()
        this.enviando = false
        return
      }
      this.error = null

      if (this.password === '' && this.id === 0) {
        this.error = 'Senha é obrigátoria'
        this.$refs.password.focus()
        this.enviando = false
        return
      }
      this.error = null

      if (!validator.isDate(this.birthDate)) {
        this.error = 'Data inválida'
        this.$refs.birthDate.focus()
        this.enviando = false
        return
      }
      this.error = null

      if (this.phone === '') {
        this.error = 'Celular é obrigátorio'
        this.$refs.phone.focus()
        this.enviando = false
        return
      }
      this.error = null

      if (this.id === 0) {
        api.post('/teachers', {
          name: this.name,
          birthDate: this.birthDate,
          email: this.email,
          phone: this.phone,
          bio: this.bio,
          resume: this.resume,
          password: this.password
        }).then(res => {
          if (res.status === 201) {
            Swal.fire({
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.$router.push('/admin/professores')
            })
          } else {
            this.error = 'Professor não foi criado'
          }

          this.enviando = false
        }).catch(err => {
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      } else {
        api.patch(`/teachers/${this.id}`, {
          name: this.name,
          birthDate: this.birthDate,
          email: this.email,
          phone: this.phone,
          bio: this.bio,
          resume: this.resume,
          password: this.password
        }).then(res => {
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.$router.push('/admin/professores')
            })
          } else {
            this.error = 'Professor não foi atualizado'
          }

          this.enviando = false
        }).catch(err => {
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      }
    }
  }
}
</script>
